exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-clearance-js": () => import("./../../../src/pages/clearance.js" /* webpackChunkName: "component---src-pages-clearance-js" */),
  "component---src-pages-combined-js": () => import("./../../../src/pages/combined.js" /* webpackChunkName: "component---src-pages-combined-js" */),
  "component---src-pages-feed-js": () => import("./../../../src/pages/feed.js" /* webpackChunkName: "component---src-pages-feed-js" */),
  "component---src-pages-hub-js": () => import("./../../../src/pages/hub.js" /* webpackChunkName: "component---src-pages-hub-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-centre-results-page-js": () => import("./../../../src/pages/learn-centre-results-page.js" /* webpackChunkName: "component---src-pages-learn-centre-results-page-js" */),
  "component---src-pages-post-purchase-js": () => import("./../../../src/pages/post-purchase.js" /* webpackChunkName: "component---src-pages-post-purchase-js" */),
  "component---src-pages-product-selector-js": () => import("./../../../src/pages/product-selector.js" /* webpackChunkName: "component---src-pages-product-selector-js" */),
  "component---src-pages-sale-js": () => import("./../../../src/pages/sale.js" /* webpackChunkName: "component---src-pages-sale-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-showroom-landing-js": () => import("./../../../src/pages/showroom-landing.js" /* webpackChunkName: "component---src-pages-showroom-landing-js" */),
  "component---src-pages-specials-js": () => import("./../../../src/pages/specials.js" /* webpackChunkName: "component---src-pages-specials-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-area-js": () => import("./../../../src/templates/area.js" /* webpackChunkName: "component---src-templates-area-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-learn-category-page-js": () => import("./../../../src/templates/learn-category-page.js" /* webpackChunkName: "component---src-templates-learn-category-page-js" */),
  "component---src-templates-learn-subcategory-page-js": () => import("./../../../src/templates/learn-subcategory-page.js" /* webpackChunkName: "component---src-templates-learn-subcategory-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-collection-js": () => import("./../../../src/templates/product-collection.js" /* webpackChunkName: "component---src-templates-product-collection-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-landing-js": () => import("./../../../src/templates/product-landing.js" /* webpackChunkName: "component---src-templates-product-landing-js" */),
  "component---src-templates-product-upsell-js": () => import("./../../../src/templates/product-upsell.js" /* webpackChunkName: "component---src-templates-product-upsell-js" */),
  "component---src-templates-showroom-js": () => import("./../../../src/templates/showroom.js" /* webpackChunkName: "component---src-templates-showroom-js" */),
  "component---src-templates-showroom-list-js": () => import("./../../../src/templates/showroom-list.js" /* webpackChunkName: "component---src-templates-showroom-list-js" */),
  "component---src-templates-spa-parts-js": () => import("./../../../src/templates/spa-parts.js" /* webpackChunkName: "component---src-templates-spa-parts-js" */)
}

